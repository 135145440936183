<template>
    <el-dialog title="新增分享商戶"
               v-model="show"
               @close="closeDialog"
               width="480px"
               center>
        <el-form ref="dialogForm"
                 :model="dialogForm"
                 label-width="120px">
            <el-form-item label="商戶ID:"
                          style="margin-bottom:0px">
                <el-input v-model="dialogForm.shareId"
                          placeholder="請輸入商戶ID"></el-input>
            </el-form-item>
            <el-form-item>

                <div v-if="openShare">{{checkShareId[0].text}}</div>
                <div v-else
                     style="color:red">查無此商戶ID</div>
            </el-form-item>
        </el-form>

        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary"
                           v-if="openShare"
                           @click="saveShareDomain">新增</el-button>
                <el-button type="info"
                           v-else
                           disabled>新增</el-button>

            </span>
        </template>
    </el-dialog>
</template>

<script>
import { shareDomain } from '../../../../api/index'
export default {
    props: {
        shareDialogVisible: {
            default: false,
        },
        shareForm: {},
        merchantShareList: {},
        userId: {},
    },
    data() {
        return {
            show: this.shareDialogVisible, //燈窗顯示與否
            dialogForm: this.shareForm,
            openShare: false,
        }
    },
    computed: {
        checkShareId() {
            let result = this.merchantShareList.filter((word) => word.value == this.shareForm.shareId)
            return result
        },
    },
    watch: {
        shareDialogVisible() {
            this.show = this.shareDialogVisible
        },
        checkShareId: {
            deep: true,
            handler: function (newVal) {
                if (newVal.length > 0) {
                    this.openShare = true
                } else {
                    this.openShare = false
                }
            },
        },
    },

    methods: {
        saveShareDomain() {
            //新增分享商戶確認
            let data = {
                domain_id: this.dialogForm.domainId,
                merchant_id: this.userId,
                merchant_share_id: this.dialogForm.shareId,
            }
            shareDomain(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('新增成功！')
                    this.closeDialog()
                    this.$emit('get-data')
                } else if (res.code == 500) {
                    this.$message.error(res.message)
                }
            })
        },
        closeDialog() {
            this.$emit('close-dialog')
        },
    },
}
</script>

<style>
</style>