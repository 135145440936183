<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/commercialList' }">商戶列表</el-breadcrumb-item>
                <el-breadcrumb-item>商戶基本資料</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div>商戶ID:{{info.id}}</div>
            <div class="info-content">
                <div style="flex:1">{{info.name}}</div>
                <el-divider direction="vertical"></el-divider>
                <div style="flex:1">{{info.phone_number}}</div>
                <el-divider direction="vertical"></el-divider>
                <div style="flex:1">{{info.account}}</div>
                <el-divider direction="vertical"></el-divider>
                <div class="text-green"
                     style="flex:1">網域來源: <span v-if="info.account_count">{{info.account_count}}</span><span v-else>0</span></div>
                <div class="text-green"
                     style="flex:1">域名: <span v-if="info.domain_count">{{info.domain_count}}</span><span v-else>0</span></div>
                <div class="text-green"
                     style="flex:1">即將到期: <span v-if="info.expired_count">{{info.expired_count}}</span><span v-else>0</span></div>
                <el-divider direction="vertical"></el-divider>
                <div style="flex:.5">
                    <el-switch v-model="info.status"
                               active-color="#13ce66"
                               inactive-color="#ff4949"
                               inactive-text="啟用:"
                               active-value="Y"
                               inactive-value="N"
                               @click="changeStatus(info)">
                    </el-switch>
                </div>
                <el-divider direction="vertical"></el-divider>
                <div style="flex:.5">
                    <el-button type="text"
                               @click="toPage('editCommercial')">編輯</el-button>
                </div>
            </div>
            <div>
                <el-tabs v-model="activeSource"
                         type="card"
                         @tab-click="changeTabs">
                    <el-tab-pane v-for="(item,index) in sourceList"
                                 :key="index"
                                 :label="item.name"
                                 :name="item.value"></el-tab-pane>
                </el-tabs>
                <div class="form-content">
                    <!-- 域名查詢 -->
                    <el-form :inline="true"
                             :model="formInline"
                             class="demo-form-inline"
                             style="padding-left:10px">
                        <el-form-item label="域名:">
                            <el-input v-model="formInline.domain"
                                      placeholder="請輸入域名"></el-input>
                        </el-form-item>
                        <el-form-item label="來源註記:"
                                      prop="remark">
                            <el-select v-model="formInline.remark"
                                       multiple
                                       collapse-tags
                                       placeholder="請選擇網域來源"
                                       class="selectWidth">
                                <el-option v-for="(item,index) in remarkList"
                                           :label="item.text"
                                           :value="item.value"
                                           :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="TAG:">
                            <el-input v-model="formInline.tag"
                                      placeholder="請輸入完整TAG名稱"></el-input>
                        </el-form-item>
                        <el-form-item label="分享來源:">
                            <el-input v-model="formInline.share_from"
                                      placeholder="請填入分享來源商戶ID"></el-input>
                        </el-form-item>
                        <el-form-item label="分享給:">
                            <el-input v-model="formInline.share_to"
                                      placeholder="請填入以分享的商戶ID"></el-input>
                        </el-form-item>
                    </el-form>
                </div>

                <!--查詢按鈕 跟筆數統計 區域 -->
                <el-row class="btn-search-area">
                    <el-col :span="24"
                            class="btn-search-area-col">
                        <el-button type="primary"
                                   @click="handleSearch">搜尋</el-button>
                        <el-button @click="clearSearch">清除</el-button>
                        <div class="count-area">當前域名數 <span class="span-color-primary">{{tableData.length}}</span> 筆, 總共 <span class="span-color-primary">{{totalCount}}</span> 筆</div>
                    </el-col>
                </el-row>
                <!--域名分享 -->

                <el-row>
                    <el-col :span="24"
                            class="pd-top-bottom"
                            :md="12"><span>域名分享:</span>
                        <input ref="excel-upload-input"
                               class="excel-upload-input"
                               type="file"
                               accept=".xlsx, .xls"
                               @change="batchImportClick">
                        <el-button type="info"
                                   size="medium"
                                   @click="batchImportAdds"
                                   round
                                   style="margin-left:10px">批次匯入</el-button>
                        <el-button size="medium"
                                   @click="excelEx"
                                   round>範本下載</el-button>
                    </el-col>
                    <el-col :span="24"
                            class="pd-top-bottom"
                            :md="12">
                        <span>批次上傳標籤:</span>
                        <input ref="tag-excel-upload-input"
                               class="excel-upload-input"
                               type="file"
                               accept=".xlsx, .xls"
                               @change="tagImportClick">
                        <el-button type="info"
                                   size="medium"
                                   @click="tagAdds"
                                   round
                                   style="margin-left:10px">批次匯入</el-button>
                        <el-button size="medium"
                                   @click="tagExcelEx"
                                   round>範本下載</el-button>
                        <el-button size="medium"
                                   type="primary"
                                   @click="exportTags"
                                   round>匯出</el-button>
                    </el-col>
                </el-row>
                <!--狀態更新 -->
                <el-row class="status-update"
                        v-if="activeSource!=1">
                    <el-col>
                        <el-button type="primary"
                                   @click="openUpdateDialog">狀態更新</el-button>
                        <el-button type="warning"
                                   @click="openProjectDialog">新增專案網域</el-button>
                    </el-col>
                </el-row>
                <el-row class="status-update"
                        v-else>
                    <el-col>
                        <el-button type="warning"
                                   @click="openProjectDialog">新增專案網域</el-button>
                    </el-col>
                </el-row>
                <!-- 域名列表 -->
                <el-table ref="domainTable"
                          :data="tableData"
                          @selection-change="handleSelectionChange"
                          @sort-change="handleSortChange"
                          border
                          :row-key="getRowKey"
                          class="table">
                    <el-table-column type="selection"
                                     :reserve-selection="true">
                    </el-table-column>
                    <el-table-column prop="domain"
                                     label="域名"
                                     sortable="custom"
                                     width="180"
                                     align="center" />
                    <el-table-column width="120"
                                     label="來源註記"
                                     align="center">
                        <template #default="scope">
                            <el-tooltip class="item"
                                        effect="dark"
                                        :content="scope.row.remark"
                                        placement="top">
                                <div class="tool-tip-text"> {{scope.row.remark}}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="domain_created_at"
                                     label="創建時間"
                                     width="180"
                                     align="center" />
                    <el-table-column prop="domain_expires_at"
                                     label="到期時間"
                                     width="180"
                                     sortable="custom"
                                     align="center" />
                    <el-table-column prop="domain_renew_deadline"
                                     label="可續約時間"
                                     width="180"
                                     sortable="custom"
                                     align="center" />
                    <el-table-column label="自動續費"
                                     align="center">
                        <template #default="scope">
                            <div v-if="scope.row.is_renew_auto=='Y'">是</div>
                            <div v-else>否</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status"
                                     label="域名狀態"
                                     align="center" />
                    <el-table-column prop="updated_at"
                                     label="最後更新時間"
                                     width="180"
                                     sortable="custom"
                                     align="center" />
                    <el-table-column width="120"
                                     label="DNS指向"
                                     align="center">
                        <template #default="scope">
                            <el-tooltip class="item"
                                        effect="dark"
                                        placement="top">
                                <template #content> <template v-for="(server,idx) in scope.row.nameservers"
                                              :key="idx">
                                        {{server}}
                                        <br>
                                    </template>
                                </template>
                                <div class="tool-tip-text"
                                     v-html="nameSeverChange(scope.row.nameservers)"></div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="分享來源"
                                     align="center"
                                     :min-width="180">
                        <template #default="scope">
                            <template v-if="scope.row.share_from">
                                <a href="javascript:void(0)"
                                   @click="toUserPage('userInformation',scope.row.share_from)">{{scope.row.share_from}} </a>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column label="分享給"
                                     align="center"
                                     :min-width="180">
                        <template #default="scope">
                            <div v-if="scope.row.share_from"></div>
                            <div v-else-if="scope.row.share_list !== undefined &&scope.row.share_list.length>0">
                                <template v-for="(item, index) in scope.row.share_list"
                                          :key="index">
                                    <el-tag closable
                                            type="success"
                                            :disable-transitions="false"
                                            @close="deleteShare(item.share_id)">
                                        {{item.mid}}
                                    </el-tag>
                                </template>
                            </div>
                            <div v-else>
                                <el-button size="small"
                                           @click="openShareDialog(scope.row)">+ 新增</el-button>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="TAG"
                                     :min-width="300">
                        <template #default="scope">
                            <el-tag :key="index"
                                    v-for="(tag,index) in scope.row.tags"
                                    closable
                                    :disable-transitions="false"
                                    @close="handleClose(scope.row,index)">
                                {{tag.tag}}
                            </el-tag>
                            <el-button class="button-new-tag"
                                       size="small"
                                       @click="openTagDialog(scope.row)">+ New Tag</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination background
                                   layout=" prev, pager, next"
                                   :current-page="pageIndex"
                                   :page-size="pageSize"
                                   :page-count="pageCount"
                                   @current-change="handlePageChange">
                    </el-pagination>
                </div>
                <!-- TAG编辑弹出框 -->
                <TagDialog :tagDialogVisible="tagDialogVisible"
                           :tagList="tagList"
                           :newTagform="newTagform"
                           @close-dialog='tagDialogVisible = false'
                           @get-data='getData'></TagDialog>
                <!-- 分享弹出框-->
                <ShareDialog :shareDialogVisible="shareDialogVisible"
                             :merchantShareList="merchantShareList"
                             :userId="userId"
                             :shareForm="shareForm"
                             @close-dialog='shareDialogVisible = false'
                             @get-data='getData'></ShareDialog>
                <!--狀態更新彈出框 -->
                <UpdateDialog :updateDialogVisible="updateDialogVisible"
                              :userId="userId"
                              :count="tableData.length"
                              :activeSource="activeSource"
                              :activeSourceName="activeSourceName"
                              @close-dialog='updateDialogVisible = false'
                              @get-data='getData'></UpdateDialog>
                <!--新增專案網域彈出框 -->
                <ProjectDialog :addProjectVisible="addProjectVisible"
                               :userId="userId"
                               :selectionId="selectionId"
                               @close-dialog='addProjectVisible = false'
                               @get-data='getData'></ProjectDialog>
            </div>
        </div>
    </div>
</template>

<script>
import ShareDialog from './ShareDialog.vue'
import UpdateDialog from './UpdateDialog.vue'
import TagDialog from './TagDialog.vue'
import ProjectDialog from './ProjectDialog.vue'
import { merchantDetail, domainSourceList, deleteShareDomain, deleteDomainTag, merchantChangeStatus, shareDomainbatchImport, shareDomainExport, batchImportDomainTag, exportTagSample, exportTag } from '../../../../api/index'
export default {
    components: {
        TagDialog,
        ShareDialog,
        UpdateDialog,
        ProjectDialog,
    },
    data() {
        return {
            info: {},
            sourceList: {},
            remarkList: {},
            tagList: [],
            tableData: [{ tag: '' }],
            activeSource: '',
            activeSourceName: '',
            formInline: {
                domain: '',
                remark: [],
                tag: '',
            },
            newTagform: {
                tag: [],
            }, //新增tag
            pageIndex: 1,
            pageSize: 10,
            pageCount: 0,
            totalCount: 0, //共幾筆?
            inputVisible: false,
            inputValue: '',
            userId: '', //使用者商戶ID
            order: '',
            tagDialogVisible: false, //新增tag開啟
            shareDialogVisible: false, //新增分享商戶開啟
            updateDialogVisible: false, //狀態更新開啟
            addProjectVisible: false, //新增專案網域開啟
            shareForm: {
                shareId: '',
            },
            merchantShareList: [],
            selectionId: [], //勾選到的list
        }
    },
    watch: {
        async $route(to) {
            if (to.path == '/userInformation') {
                if (typeof this.$route.query.id == 'undefined') {
                    this.$router.push({ path: 'commercialList' })
                } else {
                    this.userId = this.$route.query.id
                    await this.getDomainSourceList()
                    this.getData()
                }
            }
        },
    },
    async mounted() {
        if (typeof this.$route.query.id == 'undefined') {
            this.$router.push({ path: 'commercialList' })
        } else {
            this.userId = this.$route.query.id
            await this.getDomainSourceList()
            this.getData()
        }
    },
    methods: {
        async getDomainSourceList() {
            var ret = await new Promise((resolve) => {
                domainSourceList().then((res) => {
                    this.sourceList = res.data.data_list
                    this.activeSource = res.data.data_list[0].value
                    this.activeSourceName = res.data.data_list[0].name
                    resolve()
                })
            })
            return ret
        },
        getData() {
            let remarkJson = JSON.stringify(this.formInline.remark)
            let data = {
                id: this.userId,
                source_id: this.activeSource,
                domain: this.formInline.domain,
                remark: remarkJson,
                order: this.order,
                tag: this.formInline.tag,
                share_from: this.formInline.share_from,
                share_to: this.formInline.share_to,
                page: this.pageIndex,
            }
            merchantDetail(data).then((res) => {
                if (res.code == 300) {
                    this.info = res.data.data_info[0]
                    this.remarkList = res.data.remark_select_list
                    this.merchantShareList = res.data.merchantShareList_select_list
                    this.tagList = res.data.tag_select_list
                    this.tableData = res.data.data_list
                    this.pageCount = res.data.total_page
                    this.totalCount = res.data.total_count
                    this.newTagform.tag = []
                    this.newTagform.tag.push({ value: this.tagList[0].value })
                } else if (res.code == 500) {
                    this.$message.error(res.message)
                }
            })
        },
        toPage(page) {
            this.$router.push({
                path: page,
                query: { id: this.userId },
            })
        },
        toUserPage(page, userid) {
            this.$router.push({
                path: page,
                query: { id: userid },
            })
        },
        handleSearch() {
            this.getData()
        },
        clearSearch() {
            this.formInline = []
        },
        changeStatus(info) {
            let data = {
                id: info.id,
                status: info.status,
            }
            merchantChangeStatus(data)
                .then((res) => {
                    if (res.code == 200) {
                        this.$message.success(res.message)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
        //排序
        handleSortChange({ prop, order }) {
            if (order == 'descending') {
                this.order = prop + '_desc'
            } else if (order == 'ascending') {
                this.order = prop + '_asc'
            } else {
                this.order = ''
            }
            this.tableData = []
            this.getData()
        },
        // 分页导航
        handlePageChange(val) {
            this.pageIndex = val
            this.getData()
        },
        handleClose(tag, index) {
            let data = {
                domain_id: tag.id,
                tag_id: tag.tags[index].domain_tag_id,
            }
            this.$confirm('确定要删除吗？', '提示', {
                type: 'warning',
            })
                .then(() => {
                    deleteDomainTag(data).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('删除成功')
                            this.getData()
                        }
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        //換網域
        changeTabs(tab) {
            this.activeSourceName = tab.props.label
            this.$refs.domainTable.clearSelection()
            this.getData()
        },
        openTagDialog(row) {
            this.newTagform.domainId = row.id
            this.tagDialogVisible = true
        },
        openShareDialog(row) {
            this.shareForm.domainId = row.id
            this.shareDialogVisible = true
        },
        openUpdateDialog() {
            this.updateDialogVisible = true
        },
        openProjectDialog() {
            if (this.selectionId.length > 0) {
                this.addProjectVisible = true
            } else {
                this.$message.error('請勾選要新增的網域')
            }
        },
        deleteShare(shareId) {
            //刪除分享商戶
            let data = {
                share_id: shareId.toString(),
            }
            this.$confirm('确定要删除吗？', '提示', {
                type: 'warning',
            })
                .then(() => {
                    deleteShareDomain(data).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('删除成功')
                            this.getData()
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        batchImportAdds() {
            this.$refs['excel-upload-input'].click()
        },
        //批量匯入
        batchImportClick(e) {
            const files = e.target.files
            const rawFile = files[0]
            let formData = new FormData()
            formData.append('excel', rawFile)
            shareDomainbatchImport(formData)
                .then((res) => {
                    if (res.code == 200) {
                        this.getData()
                        this.$message.success(res.message)
                    }
                    if (res.code == 500) {
                        this.$message.error(res.message)
                    }
                    this.$refs['excel-upload-input'].value = ''
                })
                .catch(() => {
                    this.$message.error('失敗')
                })
        },
        //excel範本
        excelEx() {
            shareDomainExport().then((res) => {
                this.excelTake(res, '域名範本下載')
            })
        },
        tagAdds() {
            this.$refs['tag-excel-upload-input'].click()
        },
        //tag批量匯入
        tagImportClick(e) {
            const files = e.target.files
            const rawFile = files[0]
            let formData = new FormData()
            formData.append('excel', rawFile)
            batchImportDomainTag(formData)
                .then((res) => {
                    this.excelTake(res, '回報標籤結果')
                    this.$refs['tag-excel-upload-input'].value = ''
                })
                .catch(() => {
                    this.$message.error('失敗')
                })
        },
        //tag excel範本
        tagExcelEx() {
            exportTagSample().then((res) => {
                this.excelTake(res, '標籤範本下載')
            })
        },
        //接收excel
        excelTake(res, title) {
            const xlsx = 'application/vnd.ms-excel'
            const blob = new Blob([res], { type: xlsx })
            const fileReader = new FileReader()
            let _this = this
            fileReader.readAsText(blob, 'utf-8')
            fileReader.onload = function () {
                if (res.type === 'application/json') {
                    let msg = JSON.parse(fileReader.result).message
                    if (JSON.parse(fileReader.result).code == 500) {
                        _this.$message.error(msg)
                    }
                } else {
                    const a = document.createElement('a') // 轉換完成，建立一個a標籤用於下載
                    a.download = `${title}.xlsx`
                    a.href = window.URL.createObjectURL(blob)
                    a.click()
                    a.remove()
                }
            }
        },
        //匯出TAG
        exportTags() {
            exportTag({ domain_id_list: this.selectionId }).then((res) => {
                let Today = new Date()
                let date = Today.getFullYear().toString() + (Today.getMonth() + 1).toString() + Today.getDate().toString()
                this.excelTake(res, '匯出選取域名與標籤' + date)
            })
        },
        //勾選key
        getRowKey(row) {
            return row.id
        },
        //有勾選到的
        handleSelectionChange(val) {
            this.selectionId = []
            val.forEach((element) => {
                this.selectionId.push(element.id)
            })
        },
        //dns指向更改
        nameSeverChange(array) {
            let a = array || []
            return a.join('<br>')
        },
    },
}
</script>

<style lang="scss" scoped>
.info-content {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid black;
    padding: 25px 5px;
    border-radius: 5px;
    margin-bottom: 60px;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    white-space: nowrap;
}
.form-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 10px;
}
.el-divider--vertical {
    height: 2em;
}
.grid-content {
    text-align: center;
}
.el-tag {
    margin-left: 10px;
    margin-bottom: 2px;
    margin-top: 2px;
}
.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}
.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}
.text-green {
    span {
        color: #0a9a0ac2;
        font-size: 20px;
        font-weight: bold;
    }
}
.tag {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
.add-tag {
    position: absolute;
    right: -35px;
    top: 7px;
}
.tagSelect {
    padding: 0 15px;
}
a:hover {
    text-decoration: underline;
}
.excel-upload-input {
    display: none;
}
.span-color-primary {
    color: #409eff;
}
.status-update {
    padding-bottom: 20px;
}
.btn-search-area {
    padding: 10px 0 25px 0;
}
.btn-search-area-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.count-area {
    margin-left: 20px;
}
.pd-top-bottom {
    padding: 20px 0px;
}
</style>