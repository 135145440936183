<template>
    <el-dialog :title="title"
               v-model="show"
               :show-close="false"
               :close-on-click-modal="false"
               :destroy-on-close="true"
               width="480px"
               center>
        <div class="update-dialog"
             v-if="!finish">
            <div>待更新域名共{{count}}筆</div>
            <div id="load"></div>
        </div>
        <div class="update-dialog"
             v-else>
            <div>已更新{{activeSourceName}}來源下所有域名狀態,請關閉視窗後確認</div>
        </div>
        <!-- <el-row>
            <el-space direction="vertical"
                      size="50">
                <el-col :span="24">
                    <div>待更新域名共{{count}}筆</div>
                </el-col>
                <el-col :span="24">
                    <div id="load">123</div>
                </el-col>
            </el-space>
        </el-row> -->
        <template #footer>
            <span class="dialog-footer">
                <el-tooltip v-if="!finish"
                            effect="light"
                            content="目前域名狀態更新中，確定取消更新?"
                            placement="top">
                    <el-button type="primary"
                               @click="stop">取消更新</el-button>
                </el-tooltip>
                <el-button type="primary"
                           v-else
                           @click="clickCloseButton">關閉</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import { syncDomain, checkInterruptCode, deleteInterruptCode } from '../../../../api/index'
export default {
    props: {
        updateDialogVisible: {
            default: false,
        },
        userId: {},
        activeSource: {},
        activeSourceName: {},
        count: {},
    },
    data() {
        return {
            show: this.updateDialogVisible, //燈窗顯示與否
            testLoading: '',
            title: '正在更新域名狀態',
            finish: false, //完成與否
            // action: 1, // 操作 1:啟動 or 0:關閉
            timer: '', //循環問
            interruptCode: '', //中斷馬
        }
    },
    watch: {
        updateDialogVisible() {
            this.show = this.updateDialogVisible
            if (this.show) {
                this.doSyncDomain(1)
                this.$nextTick(() => {
                    this.load()
                })
            }
        },
    },
    mounted() {},
    methods: {
        doSyncDomain(action) {
            let data = {
                merchant_id: this.userId,
                source_id: this.activeSource,
                action: action,
            }
            syncDomain(data).then((res) => {
                if (res.code == 300) {
                    this.interruptCode = res.data.interrupt_code
                    this.timer = setInterval(() => {
                        this.checkUpdating()
                    }, 5000)
                }
            })
        },
        checkUpdating() {
            let data = {
                interrupt_code: this.interruptCode,
            }
            checkInterruptCode(data).then((res) => {
                if (res.code == 300) {
                    if (res.data.is_syncing == 'N') {
                        this.title = '完成所有域名更新'
                        this.finish = true
                        clearInterval(this.timer)
                    }
                }
            })
        },
        cancelSyncDomian() {
            deleteInterruptCode({ interrupt_code: this.interruptCode }).then((res) => {
                if (res.code == 200) {
                    this.$message.error('已中斷')
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        closeDialog() {
            this.cancelSyncDomian()
            this.$emit('get-data')
            this.$emit('close-dialog')
            this.$nextTick(() => {
                this.finish = false
            })
        },

        stop() {
            this.endLoading()
            this.closeDialog()
        },
        load() {
            this.testLoading = this.$loading({
                target: '#load',
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
            })
        },
        endLoading() {
            this.testLoading.close()
        },
        clickCloseButton() {
            this.$emit('close-dialog')
        },
    },
}
</script>

<style lang="scss" scoped>
.update-dialog {
    text-align: center;
}
#load {
    padding: 35px 0px;
}
</style>