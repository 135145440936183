<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 網域列表
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <el-form ref="formDomain"
                     :inline="true"
                     :model="formInline"
                     class="demo-form-inline">
                <el-form-item label="域名:"
                              prop="domain">
                    <el-input v-model="formInline.domain"
                              placeholder="請輸入域名"></el-input>
                </el-form-item>
                <el-form-item label="商戶ID:"
                              prop="merchant_id">
                    <el-input v-model="formInline.merchant_id"
                              placeholder="請輸入商戶ID"></el-input>
                </el-form-item>
                <el-form-item label="網域來源:"
                              prop="source_id">
                    <el-select v-model="formInline.source_id"
                               multiple
                               collapse-tags
                               placeholder="请选择"
                               @change='changeSelect'>
                        <el-option v-for="item in sourceList"
                                   :key="item.value"
                                   :label="item.text"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="域名到期日:">
                    <el-date-picker v-model="formInline.datetime"
                                    type="daterange"
                                    :shortcuts="shortcuts"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="域名狀態:"
                              prop="status">
                    <el-select v-model="formInline.status"
                               multiple
                               collapse-tags
                               placeholder="请选择"
                               @change='changeSelect'>
                        <el-option v-for="item in statusList"
                                   :key="item.value"
                                   :label="item.text"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="TAG:"
                              prop="tag">
                    <el-input v-model="formInline.tag"
                              placeholder="請輸入TAG"></el-input>
                </el-form-item>
            </el-form>
            <el-row>
                <el-col :span="24"
                        style="    text-align: center;">
                    <el-button type="primary"
                               @click="handleSearch">搜尋</el-button>
                    <el-button @click="resetForm">清除</el-button>
                </el-col>
            </el-row>
            <el-row>
                <el-col class="count-row">
                    <div>當前 <span class="span-color-primary">{{tableData.length}}</span> 筆, 總共 <span class="span-color-primary">{{totalCount}}</span> 筆</div>
                    <div>
                        <el-button type="success"
                                   @click="exporDomian">網域列表匯出</el-button>
                    </div>
                </el-col>
            </el-row>
            <el-table :data="tableData"
                      @selection-change="handleSelectionChange"
                      :row-key="getRowKey"
                      border
                      class="table">
                <el-table-column type="selection"
                                 :reserve-selection="true">
                </el-table-column>
                <el-table-column prop="merchant_id"
                                 label="商戶ID"
                                 width="100"
                                 align="center" />
                <el-table-column prop="name"
                                 label="商戶"
                                 width="100"
                                 align="center" />
                <el-table-column prop="domain"
                                 label="域名"
                                 width="200"
                                 align="center" />
                <el-table-column prop="source_name"
                                 label="網域來源"
                                 align="center" />
                <el-table-column prop="domain_expires_at"
                                 label="到期時間"
                                 width="180"
                                 align="center" />
                <el-table-column label="自動續費"
                                 align="center">
                    <template #default="scope">
                        <div v-if="scope.row.is_renew_auto=='Y'">是</div>
                        <div v-else>否</div>
                    </template>
                </el-table-column>
                <el-table-column prop="status"
                                 label="域名狀態"
                                 align="center" />
                <el-table-column prop="updated_at"
                                 label="最後更新時間"
                                 width="180"
                                 align="center">

                </el-table-column>
                <el-table-column width="120"
                                 label="DNS指向"
                                 align="center">
                    <template #default="scope">
                        <el-tooltip class="item"
                                    effect="dark"
                                    placement="top">
                            <template #content> <template v-for="(server,idx) in scope.row.nameservers"
                                          :key="idx">
                                    {{server}}
                                    <br>
                                </template>
                            </template>
                            <div class="tool-tip-text"
                                 v-html="nameSeverChange(scope.row.nameservers)"></div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="lastRenewPrice"
                                 width="150"
                                 label="域名上次續費價格"
                                 align="center">

                </el-table-column>
                <el-table-column label="域名狀況"
                                 align="center">
                    <template #default="scope">
                        <div>{{scope.row.is_corrupted}}</div>
                        <div>{{scope.row.is_expensive}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="TAG"
                                 width="250"
                                 align="center">
                    <template #default="scope">
                        <el-tag :key="index"
                                v-for="(tag,index) in scope.row.tags"
                                :disable-transitions="false">
                            {{tag.tag}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="分享來源"
                                 width="180"
                                 align="center">
                    <template #default="scope">
                        <a href="javascript:void(0)"
                           @click="toUserPage('userInformation',scope.row.share_from)">{{scope.row.share_from}} </a>
                    </template>
                </el-table-column>
                <el-table-column label="管理"
                                 align="center">
                    <template #default="scope"
                              align="center">

                        <el-button v-if="scope.row.source_id==2"
                                   size="mini"
                                   type="primary"
                                   @click="updateDomain('userInformation',scope.row)">更新</el-button>
                        <el-button v-else
                                   size="mini"
                                   type="info"
                                   @click="detail(scope.$index, scope.row)">詳情</el-button>

                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination background
                               layout=" prev, pager, next"
                               :current-page="pageIndex"
                               :page-size="pageSize"
                               :page-count="pageCount"
                               @current-change="handlePageChange">
                </el-pagination>
            </div>
            <el-dialog title="網域詳情"
                       v-model="editVisible"
                       width="480px"
                       center>
                <json-viewer :value="details"
                             :expand-depth=5
                             copyable
                             boxed
                             sort />
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { domainList, exportDomainList } from '../../api/index'
import { dateFormat } from '@/utils/dateFormat.js'
export default {
    data() {
        return {
            formInline: {
                name: '',
                source_id: [],
                status: [
                    'ACTIVE',
                    'AWAITING',
                    'CONFISCATED',
                    'DISABLED',
                    'EXCLUDED',
                    'FAILED',
                    'HELD',
                    'LOCKED',
                    'PARKED',
                    'PENDING',
                    'RESERVED',
                    'REVERTED',
                    'SUSPENDED',
                    'UNKNOWN',
                    'UNLOCKED',
                    'UNPARKED',
                    '正常',
                    '续费宽限期',
                    '转出中',
                    '正在过户',
                    '高价赎回期',
                    '出售中',
                    '正在竞价',
                    '等待确认',
                    '解析受限',
                ],
            },
            startDay: '',
            endDay: '',
            tableData: [],
            pageIndex: 1,
            pageSize: 10,
            pageCount: 0,
            totalCount: 0, //共幾筆?
            selectionId: [], //勾選到的list
            editVisible: false, //dilog顯示
            details: '', //詳情
            sourceList: {}, //網域來源
            statusList: {}, //域名狀態
            shortcuts: [
                {
                    text: '1日',
                    value: (() => {
                        const end = new Date()
                        const start = new Date()
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 1)
                        return [start, end]
                    })(),
                },
                {
                    text: '7日',
                    value: (() => {
                        const end = new Date()
                        const start = new Date()
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 7)
                        return [start, end]
                    })(),
                },
                {
                    text: '30日',
                    value: (() => {
                        const end = new Date()
                        const start = new Date()
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 30)
                        return [start, end]
                    })(),
                },
            ],
        }
    },
    mounted() {
        if (this.$route.query.id) {
            this.formInline.merchant_id = this.$route.query.id
        }
        if (this.$route.params.tagName) {
            this.formInline.tag = this.$route.params.tagName
        }
        this.getData()
    },
    methods: {
        getData() {
            let sourceIdJson = JSON.stringify(this.formInline.source_id)
            let statusJson = JSON.stringify(this.formInline.status)
            if (this.formInline.datetime) {
                this.startDay = dateFormat(this.formInline.datetime[0])
                this.endDay = dateFormat(this.formInline.datetime[1])
            } else {
                this.startDay = ''
                this.endDay = ''
            }
            let data = {
                domain: this.formInline.domain,
                merchant_id: this.formInline.merchant_id,
                expired_at: this.startDay,
                expired_end: this.endDay,
                tag: this.formInline.tag,
                source_id: sourceIdJson,
                status: statusJson,
                page: this.pageIndex,
            }
            domainList(data)
                .then((res) => {
                    if (res.code == 300) {
                        this.tableData = res.data.data_list
                        this.pageCount = res.data.total_page
                        this.totalCount = res.data.total_count
                        this.sourceList = res.data.source_select_list
                        this.statusList = res.data.status_select_list
                    } else if (res.code == 500) {
                        this.$message.error(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.$message.error('失敗')
                })
        },
        //查詢
        handleSearch() {
            this.getData()
        },
        //清除
        resetForm() {
            this.formInline = []
            window.history.pushState({}, 0, 'https://' + window.location.host + '/domainList')
        },
        changeSelect() {
            // console.log('123')
        },
        toUserPage(page, shareId) {
            this.$router.push({
                path: page,
                query: { id: shareId },
            })
        },
        //詳情
        detail(index, row) {
            this.details = JSON.parse(row.original_data)
            this.editVisible = true
        },
        // 分页导航
        handlePageChange(val) {
            this.pageIndex = val
            this.getData()
        },
        //更新按鈕
        updateDomain(page, info) {
            this.$router.push({
                path: page,
                query: { id: info.merchant_id },
            })
        },
        //dns指向更改
        nameSeverChange(array) {
            let a = array || []
            return a.join('<br>')
        },
        //勾選key
        getRowKey(row) {
            return row.id
        },
        //有勾選到的
        handleSelectionChange(val) {
            this.selectionId = []
            val.forEach((element) => {
                this.selectionId.push(element.id)
            })
        },
        //匯出網域列表
        exporDomian() {
            let sourceIdJson = JSON.stringify(this.formInline.source_id)
            let statusJson = JSON.stringify(this.formInline.status)
            if (this.formInline.datetime) {
                this.startDay = dateFormat(this.formInline.datetime[0])
                this.endDay = dateFormat(this.formInline.datetime[1])
            } else {
                this.startDay = ''
                this.endDay = ''
            }
            let data = {
                domain: this.formInline.domain,
                merchant_id: this.formInline.merchant_id,
                source_id: sourceIdJson,
                status: statusJson,
                tag: this.formInline.tag,
                expired_at: this.startDay,
                expired_end: this.endDay,
            }

            exportDomainList(data).then((res) => {
                this.excelTake(res, '網域列表')
            })
        },
        //接收excel
        excelTake(res, title) {
            const xlsx = 'application/vnd.ms-excel'
            const blob = new Blob([res], { type: xlsx })
            const fileReader = new FileReader()
            let _this = this
            fileReader.readAsText(blob, 'utf-8')
            fileReader.onload = function () {
                if (res.type === 'application/json') {
                    if (JSON.parse(fileReader.result).code == 500) {
                        _this.$message.error('請勾選匯出的項目')
                    }
                } else {
                    const a = document.createElement('a') // 轉換完成，建立一個a標籤用於下載
                    a.download = `${title}.xlsx`
                    a.href = window.URL.createObjectURL(blob)
                    a.click()
                    a.remove()
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.count-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.table {
    margin-top: 35px;
}
@media screen and (max-width: 800px) {
    .el-dialog {
        width: 80% !important;
    }
}
.el-tag {
    margin-left: 10px;
    margin-bottom: 2px;
    margin-top: 2px;
}
// .cell {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: center;
// }
.span-color-primary {
    color: #409eff;
}
</style>