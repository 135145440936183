<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 快速查詢
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <el-form ref="formDomain"
                     :inline="true"
                     :model="formInline"
                     class="demo-form-inline">
                <el-form-item label="Api key:"
                              prop="api_key">
                    <el-input v-model="formInline.api_key"
                              placeholder="請輸入Api key"></el-input>
                </el-form-item>
                <el-form-item label="Api secret:"
                              prop="api_secret ">
                    <el-input v-model="formInline.api_secret "
                              placeholder="請輸入Api secret"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary"
                               @click="handleSearch">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button @click="resetForm">清除</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="tableData"
                      @sort-change="handleSortChange"
                      border
                      class="table">
                <el-table-column prop="domain"
                                 label="域名"
                                 width="200"
                                 align="center" />
                <el-table-column prop="domain_created_at"
                                 label="創建時間"
                                 sortable="custom"
                                 width="180"
                                 align="center" />
                <el-table-column prop="domain_expires_at"
                                 label="到期時間"
                                 sortable="custom"
                                 width="180"
                                 align="center" />
                <el-table-column prop="domain_renew_deadline"
                                 label="可續約時間"
                                 sortable="custom"
                                 width="180"
                                 align="center" />
                <el-table-column label="自動續費"
                                 align="center"
                                 width="80">
                    <template #default="scope">
                        <div v-if="scope.row.is_renew_auto">是</div>
                        <div v-else>否</div>
                    </template>
                </el-table-column>
                <el-table-column prop="status"
                                 label="域名狀態"
                                 align="center"
                                 width="120" />
                <el-table-column prop="last_update_at"
                                 label="最後更新時間"
                                 width="180"
                                 align="center" />
                <el-table-column label="管理"
                                 align="center">
                    <template #default="scope">
                        <el-button size="mini"
                                   type="info"
                                   @click="detail(scope.$index, scope.row)">詳情</el-button>

                    </template>
                </el-table-column>
            </el-table>
            <el-dialog title="網域詳情"
                       v-model="editVisible"
                       width="480px"
                       center>
                <json-viewer :value="details"
                             :expand-depth=5
                             copyable
                             boxed
                             sort />
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { quickSearch } from '../../api/index'
export default {
    data() {
        return {
            formInline: {
                name: '',
                datetime: '',
                from: [],
                status: [],
                api_key: process.env.VUE_APP_DOMAINLIST_API_KEY,
                api_secret: process.env.VUE_APP_DOMAINLIST_API_SECREC,
            },
            tableData: [],
            pageIndex: 1,
            pageCount: 1,
            editVisible: false, //dilog顯示
            details: '', //詳情
            order: '', //排序
            options: [
                {
                    value: 'godaddy',
                    label: 'godaddy',
                },
                {
                    value: 'hinet',
                    label: 'hinet',
                },
            ],
            optionsStatus: [
                {
                    value: '正常',
                    label: '正常',
                },
                {
                    value: '等待中',
                    label: '等待中',
                },
                {
                    value: '已取消',
                    label: '已取消',
                },
            ],
        }
    },
    methods: {
        getData() {
            let data = {
                api_key: this.formInline.api_key,
                api_secret: this.formInline.api_secret,
                order: this.order,
            }
            quickSearch(data)
                .then((res) => {
                    if (res.code == 300) {
                        this.tableData = res.data.data_list
                    } else if (res.code == 500) {
                        this.$message.error(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.$message.error('失敗')
                })
        },
        //查詢
        handleSearch() {
            this.getData()
        },
        //清除
        resetForm() {
            this.formInline = []
        },
        changeSelect() {
            // console.log('123')
        },
        //詳情
        detail(index, row) {
            this.details = JSON.parse(row.original_data)
            this.editVisible = true
        },
        //排序
        handleSortChange({ prop, order }) {
            if (order == 'descending') {
                if (prop == 'domain_created_at') {
                    this.order = 'created_desc'
                } else if (prop == 'domain_expires_at') {
                    this.order = 'expires_desc'
                } else if (prop == 'domain_renew_deadline') {
                    this.order = 'renew_desc'
                }
            } else if (order == 'ascending') {
                if (prop == 'domain_created_at') {
                    this.order = 'created_asc'
                } else if (prop == 'domain_expires_at') {
                    this.order = 'expires_asc'
                } else if (prop == 'domain_renew_deadline') {
                    this.order = 'renew_asc'
                }
            } else {
                this.order = ''
            }
            this.getData()
        },
    },
}
</script>

<style>
@media screen and (max-width: 800px) {
    .el-dialog {
        width: 80% !important;
    }
}
</style>