<template>
    <el-dialog title="新增專案網域"
               v-model="show"
               @close="closeDialog"
               width="480px"
               center>
        <el-form ref="dialogForm"
                 :rules="rules"
                 :model="dialogForm"
                 label-width="120px">
            <el-form-item label="專案"
                          prop="project_id">
                <el-select v-model="dialogForm.project_id"
                           placeholder="請選擇">
                    <el-option v-for="item in list"
                               :key="item.id"
                               :label="item.name"
                               :value="item.id">
                    </el-option>

                </el-select>
            </el-form-item>
        </el-form>

        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary"
                           @click="saveDomain">新增</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import { projectList, createProjectDomain } from '../../../../api/index'
export default {
    props: {
        addProjectVisible: {
            default: false,
        },
        selectionId: {},
        userId: {},
    },
    data() {
        return {
            show: this.addProjectVisible, //燈窗顯示與否
            dialogForm: {},
            list: {},
            rules: {
                project_id: [
                    {
                        required: true,
                        message: '請選擇專案',
                        trigger: 'change',
                    },
                ],
            },
        }
    },
    watch: {
        addProjectVisible() {
            this.show = this.addProjectVisible
            if (this.show) {
                this.getProject()
            }
        },
    },
    methods: {
        getProject() {
            projectList({
                merchant_id: this.userId,
            })
                .then((res) => {
                    if (res.code == 300) {
                        this.list = res.data.data_list
                    }
                    if (res.code == 500) {
                        this.$message.error(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        saveDomain() {
            this.$refs['dialogForm'].validate((valid) => {
                if (valid) {
                    let data = {
                        project_id: this.dialogForm.project_id,
                        domain_ids: this.selectionId,
                    }
                    createProjectDomain(data).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('新增成功！')
                            this.closeDialog()
                            this.$emit('get-data')
                        } else if (res.code == 500) {
                            this.$message.error(res.message)
                        }
                    })
                }
            })
        },
        closeDialog() {
            this.$emit('close-dialog')
        },
    },
}
</script>

<style>
</style>