<template>
    <el-dialog title="新增網域標籤"
               v-model="show"
               @close="closeDialog"
               width="480px"
               center>
        <el-form ref="tagform"
                 :model="tagform"
                 label-width="120px">
            <el-form-item label="網域標籤">
                <div v-for="(item, index) in tagform.tag"
                     :key="index"
                     class="tag">
                    <el-select v-model="item.value"
                               placeholder="請選擇"
                               class="tagSelect">
                        <el-option v-for="item in tagList"
                                   :key="item.value"
                                   :label="item.text"
                                   :value="item.value">
                        </el-option>

                    </el-select>
                    <el-button v-if="index==0"
                               icon="el-icon-circle-plus-outline"
                               size="mini"
                               @click="addTag"
                               circle></el-button>
                    <el-button v-else
                               icon="el-icon-remove-outline"
                               size="mini"
                               type="danger"
                               @click="removeTag(index)"
                               circle></el-button>
                </div>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary"
                           @click="saveEdit">確 定</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import { createDomainTag } from '../../../../api/index'
export default {
    props: {
        tagList: {},
        tagDialogVisible: {
            default: false,
        },
        newTagform: {},
    },
    data() {
        return {
            show: this.tagDialogVisible, //燈窗顯示與否
            tagform: this.newTagform,
        }
    },
    watch: {
        tagDialogVisible() {
            this.show = this.tagDialogVisible
        },
    },
    methods: {
        //增加tag
        addTag() {
            this.tagform.tag.push({ value: this.tagList[0].value })
        },
        //removeTag
        removeTag(index) {
            if (index !== -1) {
                this.tagform.tag.splice(index, 1)
            }
        },
        saveEdit() {
            let tagId = []
            this.tagform.tag.forEach((element) => {
                tagId.push(element.value)
            })
            let data = {
                domain_id: this.tagform.domainId,
                tag_id: JSON.stringify(tagId),
            }
            createDomainTag(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('新增成功！')
                    this.closeDialog()
                    this.$emit('get-data')
                } else if (res.code == 500) {
                    this.$message.error(res.message)
                }
            })
        },
        closeDialog() {
            this.$emit('close-dialog')
        },
    },
}
</script>

<style>
</style>