<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 網域標籤列表
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-row class="add-btn">
            <el-col>
                <el-button type="primary"
                           @click="toPage('createTag')">新增標籤<i class="el-icon-lx-add el-icon--right"></i></el-button>
            </el-col>
        </el-row>
        <div class="container">
            <el-form ref="formTag"
                     :inline="true"
                     :model="formInline"
                     class="demo-form-inline"
                     @submit.prevent>
                <el-form-item>
                    <el-button type="primary"
                               icon="el-icon-delete"
                               @click="deletAllTag">批量刪除</el-button>
                </el-form-item>
                <el-form-item label="網域標籤:"
                              prop="tag">
                    <el-input v-model="formInline.tag"
                              placeholder="請輸入網域標籤"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary"
                               @click="handleSearch">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button @click="resetForm">清除</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="tableData"
                      @selection-change="handleSelectionChange"
                      border
                      class="table">
                <el-table-column type="selection" />

                <el-table-column prop="tag"
                                 width="180"
                                 label="網域標籤"
                                 align="center" />
                <el-table-column prop="tag_description"
                                 label="網域標籤說明"
                                 align="center" />
                <el-table-column label="管理"
                                 align="center">
                    <template #default="scope">
                        <el-button type="text"
                                   icon="el-icon-search"
                                   @click="searchDomainTag('domainList',scope.row)">搜尋</el-button>
                        <el-button type="text"
                                   icon="el-icon-edit"
                                   @click="handleEdit('editTag', scope.row)">編輯</el-button>
                        <el-button type="text"
                                   icon="el-icon-delete"
                                   class="red"
                                   @click="handleDelete(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分頁 -->
            <div class="pagination">
                <el-pagination background
                               layout=" prev, pager, next"
                               :current-page="pageIndex"
                               :page-size="pageSize"
                               :page-count="pageCount"
                               @current-change="handlePageChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { tagList, tagDelete } from '../../api/index'
export default {
    data() {
        return {
            formInline: {
                tag: '',
            },
            tableData: [],
            pageIndex: 1,
            pageSize: 10,
            pageCount: 0,
            multipleSelection: [],
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            let data = {
                tag: this.formInline.tag,
                page: this.pageIndex,
            }
            tagList(data).then((res) => {
                if (res.code == 300) {
                    this.tableData = res.data.data_list.data_list
                    this.pageCount = res.data.data_list.total_page
                } else if (res.code == 500) {
                    this.$message.error(res.message)
                }
            })
        },
        toPage(page) {
            this.$router.push({ path: page })
        },
        //查詢
        handleSearch() {
            this.getData()
        },
        searchDomainTag(page, row) {
            this.$router.push({ name: page, params: { tagName: row.tag } })
        },
        //清除
        resetForm() {
            this.$refs['formTag'].resetFields()
        },
        //刪除勾選
        handleDelete(row) {
            let id = []
            id.push(row.id)
            this.$confirm('确定要删除吗？', '提示', {
                type: 'warning',
            })
                .then(() => {
                    tagDelete({ id: JSON.stringify(id) }).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('删除成功')
                            this.getData()
                        }
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        //刪除勾選全部
        deletAllTag() {
            if (this.multipleSelection.length > 0) {
                this.$confirm('确定要删除吗？', '提示', {
                    type: 'warning',
                })
                    .then(() => {
                        tagDelete({ id: JSON.stringify(this.multipleSelection) }).then((res) => {
                            if (res.code == 200) {
                                this.$message.success('删除成功')
                                this.getData()
                            }
                        })
                    })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除',
                        })
                    })
            } else {
                this.$message.warning('請勾選要刪除的選項')
            }
        },

        //勾選
        handleSelectionChange(val) {
            this.multipleSelection = []
            val.forEach((element) => {
                this.multipleSelection.push(element.id)
            })
        },
        //編輯
        handleEdit(page, info) {
            this.$router.push({
                path: page,
                query: { id: info.id },
            })
        },
        // 分页导航
        handlePageChange(val) {
            this.pageIndex = val
            this.getData()
        },
    },
}
</script>

<style lang="scss" scoped>
.add-btn {
    padding-bottom: 15px;
}
.red {
    color: #ff0000;
}
</style>