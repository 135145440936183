<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 商戶列表
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-row class="add-btn">
            <el-col>
                <el-button type="primary"
                           @click="toPage('createCommercial')">新增商戶<i class="el-icon-lx-add el-icon--right"></i></el-button>
            </el-col>
        </el-row>
        <div class="container">
            <el-form :inline="true"
                     :model="formInline"
                     class="demo-form-inline">
                <el-form-item label="商戶ID:">
                    <el-input v-model="formInline.id"
                              placeholder="請輸入商戶ID"></el-input>
                </el-form-item>
                <el-form-item label="商戶名:">
                    <el-input v-model="formInline.name"
                              placeholder="請輸入商戶名"></el-input>
                </el-form-item>
                <el-form-item label="手機號:">
                    <el-input v-model="formInline.phone_number"
                              placeholder="請輸入手機號"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary"
                               @click="handleSearch">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button @click="clearSearch">清除</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="tableData"
                      v-loading="listLoading"
                      @sort-change="handleSortChange"
                      border
                      class="table">
                <el-table-column prop="id"
                                 label="商戶ID"
                                 sortable="custom"
                                 width="95"
                                 align="center" />
                <el-table-column prop="name"
                                 label="商戶"
                                 sortable="custom"
                                 align="center" />
                <el-table-column prop="phone_number"
                                 label="商戶手機號"
                                 width="120"
                                 align="center" />
                <el-table-column prop="account_count"
                                 label="域名來源數"
                                 width="115"
                                 align="center" />
                <el-table-column prop="domain_count"
                                 label="域名總數"
                                 width="110"
                                 sortable="custom"
                                 align="center" />
                <el-table-column prop="expired_count"
                                 label="過期域名數"
                                 width="120"
                                 sortable="custom"
                                 align="center" />
                <el-table-column prop="created_at"
                                 label="創建日期"
                                 width="180"
                                 align="center" />
                <el-table-column label="啟用"
                                 width="65"
                                 align="center">
                    <template #default="scope">
                        <el-switch v-model="scope.row.status"
                                   active-color="#13ce66"
                                   inactive-color="#ff4949"
                                   active-value="Y"
                                   inactive-value="N"
                                   @change="changeStatus(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="管理"
                                 align="center"
                                 width="350">
                    <template #default="scope">
                        <el-row class="btn-group">
                            <el-col>
                                <el-button size="mini"
                                           @click="toPage('userInformation',scope.row,'edit')">商戶明細</el-button>
                                <el-button size="mini"
                                           @click="toPage('projectList',scope.row,'edit')">監控</el-button>
                                <el-button icon="el-icon-edit"
                                           size="mini"
                                           @click="toPage('editCommercial',scope.row,'edit')">编辑商戶</el-button>
                            </el-col>
                            <el-col class="padding-top-class">
                                <el-button icon="el-icon-edit"
                                           size="mini"
                                           @click="toPage('editDomain',scope.row,'edit')">域名來源</el-button>
                                <el-button size="mini"
                                           @click="toPage('tgServices',scope.row,'edit')">TG機器人</el-button>
                                <el-button icon="el-icon-delete"
                                           class="red"
                                           size="mini"
                                           @click="handleDelete(scope.row)">删除</el-button>
                            </el-col>
                        </el-row>

                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination background
                               layout=" prev, pager, next"
                               :current-page="pageIndex"
                               :page-size="pageSize"
                               :page-count="pageCount"
                               @current-change="handlePageChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { merchantList, merchantDelete, merchantChangeStatus } from '../../../api/index'
export default {
    name: 'commercialList',
    data() {
        return {
            tableData: [],
            listLoading: false,
            search: '',
            pageIndex: 1,
            pageSize: 10,
            pageCount: 0,
            formInline: {
                id: '',
                name: '',
                phone_number: '',
            },
            order: '',
        }
    },
    watch: {
        formInline: {
            handler() {
                window.history.pushState({}, 0, '/commercialList?id=' + this.formInline.id + '&name=' + this.formInline.name + '&phone_number=' + this.formInline.phone_number + '&page=' + this.pageIndex)
            },
            deep: true,
        },
    },
    created() {
        if (typeof this.$route.query.id != 'undefined' || typeof this.$route.query.name != 'undefined' || typeof this.$route.query.phone_number != 'undefined' || typeof this.$route.query.page != 'undefined') {
            this.formInline.id = this.$route.query.id
            this.formInline.name = this.$route.query.name
            this.formInline.phone_number = this.$route.query.phone_number
            this.pageIndex = parseInt(this.$route.query.page)
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        // 获取 easy-mock 的模拟数据
        getData() {
            this.listLoading = true

            merchantList({
                id: this.formInline.id,
                name: this.formInline.name,
                phone_number: this.formInline.phone_number,
                page: this.pageIndex,
                order: this.order,
            })
                .then((res) => {
                    if (res.code == 300) {
                        this.pageCount = res.data.total_page
                        this.tableData = res.data.data_list
                    }
                    if (res.code == 500) {
                        this.$message.error(res.message)
                    }
                    this.listLoading = false
                })
                .catch((err) => {
                    console.log(err)
                    this.listLoading = false
                })
        },
        // 触发搜索按钮
        handleSearch() {
            window.history.pushState({}, 0, 'https://' + window.location.host + '/commercialList?id=' + this.formInline.id + '&name=' + this.formInline.name + '&phone_number=' + this.formInline.phone_number + '&page=' + this.pageIndex)
            this.getData()
        },
        //清除搜索
        clearSearch() {
            this.formInline = { id: '', name: '', phone_number: '' }
            window.history.pushState({}, 0, 'https://' + window.location.host + '/commercialList?id=' + this.formInline.id + '&name=' + this.formInline.name + '&phone_number=' + this.formInline.phone_number + '&page=' + this.pageIndex)
        },
        toPage(page, info, edit) {
            if (edit == 'edit') {
                this.$router.push({
                    path: page,
                    query: { id: info.id },
                })
            } else {
                this.$router.push({ path: page })
            }
        },
        //切換狀態switch
        changeStatus(row) {
            let data = {
                id: row.id,
                status: row.status,
            }
            merchantChangeStatus(data)
                .then((res) => {
                    if (res.code == 200) {
                        this.$message.success(res.message)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
        handleDelete(row) {
            this.$confirm('确定要删除吗？', '提示', {
                type: 'warning',
            })
                .then(() => {
                    merchantDelete({ id: row.id }).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('删除成功')
                            this.getData()
                        }
                    })
                })
                .catch(() => {})
        },
        //排序
        handleSortChange({ prop, order }) {
            if (order == 'descending') {
                this.order = prop + '_desc'
            } else if (order == 'ascending') {
                this.order = prop + '_asc'
            } else {
                this.order = ''
            }

            // 因為按下排序的時候他會重新計算表格寬, 然後又算不對導致旁邊會有個像scrollBar的東西
            // 解決方法一:按下排序後把資料清空(讓他計算空表格時的寬度, 排序資料都會重API撈)
            this.tableData = []

            this.getData()
        },
        // 分页导航
        handlePageChange(val) {
            this.pageIndex = val
            window.history.pushState({}, 0, 'https://' + window.location.host + '/commercialList?id=' + this.formInline.id + '&name=' + this.formInline.name + '&phone_number=' + this.formInline.phone_number + '&page=' + this.pageIndex)
            this.getData()
        },
    },
}
</script>

<style lang="scss" scoped>
.handle-box {
    display: flex;
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.table-head {
    font-size: 14px !important;
}

.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
.add-btn {
    padding-bottom: 15px;
}
.el-table .cell {
    position: relative;
}
.el-table .caret-wrapper {
    position: absolute;
    top: 2px;
    right: 0;
}
.el-switch.is-disabled {
    opacity: 1;
}
.btn-group button {
    width: 95px;
}
.padding-top-class {
    padding-top: 10px;
}
</style>

