<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/commercialList' }">商戶列表</el-breadcrumb-item>
                <el-breadcrumb-item>域名來源編輯</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <h3 class="title">域名來源設定</h3>
                <el-button @click="handleEdit"
                           size="small"
                           round>新增</el-button>
                <input ref="excel-upload-input"
                       class="excel-upload-input"
                       type="file"
                       accept=".xlsx, .xls"
                       @change="handleClick">
                <el-button type="info"
                           size="small"
                           @click="handleAdds"
                           round
                           style="margin-left:10px">批次匯入</el-button>
                <el-button size="small"
                           @click="excelEx"
                           round>範本下載</el-button>

            </div>
            <el-form :inline="true"
                     :model="formInline"
                     class="demo-form-inline">
                <el-form-item label="來源註記">
                    <el-input v-model="formInline.remark"
                              placeholder="請輸入來源註記關鍵字"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary"
                               @click="handleSearch">查詢</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button @click="clearSearch">清除</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="tableData"
                      border
                      class="table">
                <el-table-column prop="name"
                                 label="網域來源"
                                 width="180"
                                 align="center">
                </el-table-column>
                <el-table-column prop="key1"
                                 label="API KEY"
                                 align="center">
                </el-table-column>
                <el-table-column prop="remark"
                                 label="來源註記"
                                 width="180"
                                 align="center">
                </el-table-column>
                <el-table-column prop="status"
                                 label="來源狀態"
                                 width="180"
                                 align="center">
                </el-table-column>
                <el-table-column prop="google_2fa"
                                 label="Google金鑰"
                                 width="180"
                                 align="center">
                    <template #default="scope">
                        <el-button v-if="scope.row.google_2fa != null"
                                   type="text"
                                   @click="handleShowGoogle2FA(scope.row.google_2fa)">查看金鑰</el-button>
                        <div v-else>-</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作"
                                 width="180"
                                 align="center">
                    <template #default="scope">
                        <el-button type="text"
                                   icon="el-icon-edit"
                                   @click="handleEdit(scope.$index, scope.row)">編輯</el-button>
                        <el-button type="text"
                                   icon="el-icon-delete"
                                   class="red"
                                   @click="handleDelete(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分頁 -->
            <div class="pagination">
                <el-pagination background
                               layout=" prev, pager, next"
                               :current-page="pageIndex"
                               :page-size="pageSize"
                               :page-count="pageCount"
                               @current-change="handlePageChange">
                </el-pagination>
            </div>
            <!-- 编辑弹出框 -->
            <el-dialog :title="title +'&nbsp&nbsp網域來源'"
                       v-model="editVisible"
                       width="580px"
                       destroy-on-close
                       center>
                <el-form ref="form"
                         :model="form"
                         label-width="180px"
                         :rules="rules">
                    <el-form-item label="來源註記"
                                  prop="remark">
                        <el-input v-model="form.remark"></el-input>
                    </el-form-item>
                    <el-form-item label="網域來源"
                                  prop="source_id">
                        <el-select v-model="form.source_id"
                                   placeholder="請選擇網域來源"
                                   class="selectWidth">
                            <el-option v-for="(item,index) in domainSelect"
                                       :label="item.text"
                                       :value="item.value"
                                       :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- Godaddy經銷 CLRO -->
                    <template v-if="form.source_id=='2'">
                        <el-form-item label="帳號"
                                      prop="api_key">
                            <el-input v-model="form.api_key"
                                      placeholder="經銷子帳號的帳號"></el-input>
                        </el-form-item>
                        <el-form-item label="密碼"
                                      prop="api_secret">
                            <el-input v-model="form.api_secret"
                                      placeholder="經銷子帳號的密碼"></el-input>
                        </el-form-item>
                        <el-form-item label="Google 2FA 驗證金鑰"
                                      prop="google_2fa">
                            <el-input v-model="form.google_2fa"
                                      placeholder="Google 2FA 驗證金鑰"></el-input>
                        </el-form-item>
                    </template>
                    <!-- CIRo手動匯入 -->
                    <template v-else-if="form.source_id=='5'">
                        <el-form-item label="上傳檔案"
                                      prop="excel">
                            <el-upload class="upload-demo"
                                       action=""
                                       :auto-upload="false"
                                       :on-change="(file, fileList) => {changeFile(file, fileList, 'excel')}"
                                       :on-remove="handleRemove"
                                       list-type="text"
                                       accept=".xls,.xlsx,.csv"
                                       multiple>
                                <el-button size="small"
                                           type="primary">上傳檔案</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">*備註:可以上傳多筆excel檔案</div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </template>
                    <!-- Hexonet手動匯入 -->
                    <template v-else-if="form.source_id=='9'">
                        <el-form-item label="上傳檔案"
                                      prop="excel">
                            <el-upload class="upload-demo"
                                       action=""
                                       :auto-upload="false"
                                       :on-change="(file, fileList) => {changeFile(file, fileList, 'excel')}"
                                       :on-remove="handleRemove"
                                       list-type="text"
                                       accept=".xls,.xlsx,.csv"
                                       multiple>
                                <el-button size="small"
                                           type="primary">上傳檔案</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">*備註:可以上傳多筆excel檔案</div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </template>
                    <template v-else-if="form.source_id=='6'">
                        <el-form-item label="API_KEY"
                                      prop="api_key">
                            <el-input v-model="form.api_key"></el-input>
                        </el-form-item>
                    </template>
                    <template v-else>
                        <el-form-item label="API_KEY"
                                      prop="api_key">
                            <el-input v-model="form.api_key"></el-input>
                        </el-form-item>
                        <el-form-item label="API_SECRET"
                                      prop="api_secret">
                            <el-input v-model="form.api_secret"></el-input>
                        </el-form-item>
                        <el-form-item v-if="form.source_id=='3'"
                                      label="使用者名稱"
                                      prop="user_name">
                            <el-input v-model="form.user_name"></el-input>
                        </el-form-item>
                    </template>

                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="editVisible = false">取 消</el-button>
                        <el-button type="primary"
                                   @click="saveEdit">确 定</el-button>
                    </span>
                </template>
            </el-dialog>
            <!--Google 2FA -->
            <Google2FA :google2faDialogVisible="google2faDialogVisible"
                       :google2fa="google2fa"
                       @close-dialog="google2faDialogVisible = false">
            </Google2FA>
        </div>
    </div>
</template>

<script>
import { merchantAccount, domainSelectList, domainSourceCreate, domainSourceUpdateList, domainSourceDelete, domainSourceUpdate, domainSourceExport } from '../../../api/index'
import { domainSourcebatchImport } from '../../../api/index'
import Google2FA from '../../../components/Google2FA.vue'
// import axios from 'axios'
export default {
    components: { Google2FA },
    data() {
        return {
            tableData: [],
            idx: '',
            form: {},
            editVisible: false,
            title: '新增',
            pageIndex: 1,
            pageSize: 10,
            pageCount: 1,
            formInline: {
                remark: '',
            },
            editId: '', //商戶ID
            domainSelect: [],
            rules: {
                remark: [
                    {
                        required: true,
                        message: '請輸入來源註記',
                        trigger: 'blur',
                    },
                ],
                source_id: [{ required: true, message: '請選擇網域來源', trigger: 'change' }],
                api_key: [
                    {
                        required: true,
                        message: '請輸入API_KEY',
                        trigger: 'blur',
                    },
                ],
                api_secret: [
                    {
                        required: true,
                        message: '請輸入API_SECRET',
                        trigger: 'blur',
                    },
                ],
                google_2fa: [
                    {
                        required: false,
                        message: '請輸入 Google 2FA 驗證金鑰',
                        trigger: 'blur',
                    },
                ],
                user_name: [
                    {
                        required: true,
                        message: '請輸入使用者名稱',
                        trigger: 'blur',
                    },
                ],
                excel: [
                    {
                        required: true,
                        message: '請選取檔案',
                        trigger: 'change',
                    },
                ],
            },
            google2faDialogVisible: false, //google2fa彈窗
            google2fa: {},
        }
    },
    mounted() {
        if (typeof this.$route.query.id == 'undefined') {
            this.$router.push({ path: 'commercialList' })
        } else {
            this.editId = this.$route.query.id
            this.getData()
            this.getDomainList()
        }
    },
    methods: {
        getData() {
            let data = {
                merchant_id: this.editId,
                remark: this.formInline.remark,
                page: this.pageIndex,
            }
            merchantAccount(data)
                .then((res) => {
                    if (res.code == 300) {
                        this.tableData = res.data.data_list
                        this.pageCount = res.data.total_page
                    }
                })
                .catch(() => {
                    this.$message.error('失敗')
                })
        },
        getDomainList() {
            domainSelectList()
                .then((res) => {
                    if (res.code == 300) {
                        this.domainSelect = res.data.source_select_list
                    }
                })
                .catch(() => {
                    this.$message.error('失敗')
                })
        },
        handleAdds() {
            this.$refs['excel-upload-input'].click()
        },
        //批量匯入
        handleClick(e) {
            const files = e.target.files
            const rawFile = files[0]
            let formData = new FormData()
            formData.append('merchant_id', this.editId)
            formData.append('excel', rawFile)
            domainSourcebatchImport(formData)
                .then((res) => {
                    if (res.code == 200) {
                        this.getData()
                        this.getDomainList()
                        this.$message.success(res.message)
                    }
                    if (res.code == 500) {
                        this.$message.error(res.message)
                    }
                    this.$refs['excel-upload-input'].value = ''
                })
                .catch(() => {
                    this.$message.error('失敗')
                })
        },
        // 编辑操作
        handleEdit(index, row) {
            if (typeof row == 'undefined') {
                this.title = '新增'
                this.form = {}
                this.editVisible = true
            } else {
                this.title = '編輯'
                this.idx = index
                let data = {
                    id: row.id,
                }
                domainSourceUpdateList(data)
                    .then((res) => {
                        if (res.code == 300) {
                            this.form = res.data.data_info
                        }
                    })
                    .catch(() => {
                        this.$message.error('失敗')
                    })

                this.editVisible = true
            }
        },
        // 新增&编辑
        saveEdit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let domainFormData = new FormData()
                    /** 重新塞資料 */
                    for (var key in this.form) {
                        if (this.form[key]) {
                            if (key === 'excel') {
                                for (var i in this.form[key]) {
                                    domainFormData.append(key + '[' + i + ']', this.form[key][i])
                                }
                            } else {
                                domainFormData.append(key, this.form[key])
                            }
                        }
                    }
                    if (this.title == '新增') {
                        domainFormData.append('merchant_id', this.editId)
                        domainSourceCreate(domainFormData)
                            .then((res) => {
                                if (res.code == 200) {
                                    this.getData()
                                    this.$message.success(res.message)
                                    this.editVisible = false
                                } else {
                                    this.$message.error(res.message)
                                }
                            })
                            .catch(() => {
                                this.$message.error('失敗')
                            })
                    } else {
                        domainSourceUpdate(domainFormData)
                            .then((res) => {
                                if (res.code == 200) {
                                    this.getData()
                                    this.$message.success(res.message)
                                    this.editVisible = false
                                } else {
                                    this.$message.error(res.message)
                                }
                            })
                            .catch(() => {
                                this.$message.error('失敗')
                            })
                    }
                } else {
                    this.$message.error('失敗')
                    return false
                }
            })
        },
        //刪除
        handleDelete(row) {
            this.$confirm('确定要删除吗？', '提示', {
                type: 'warning',
            })
                .then(() => {
                    let data = {
                        id: row.id,
                    }
                    domainSourceDelete(data)
                        .then((res) => {
                            if (res.code == 200) {
                                this.$message.success(res.message)
                                this.getData()
                            }
                        })
                        .catch(() => {
                            this.$message.error('失敗')
                        })
                })
                .catch(() => {})
        },
        // 搜尋
        handleSearch() {
            window.history.pushState({}, 0, 'https://' + window.location.host + '/editDomain?id=' + this.editId + '&remark=' + this.formInline.remark + '&page=' + this.pageIndex)
            this.getData()
        },
        // 清除搜尋
        clearSearch() {
            this.formInline = { remark: '' }
            window.history.pushState({}, 0, 'https://' + window.location.host + '/editDomain?id=' + this.editId + '&remark=' + this.formInline.remark + '&page=' + this.pageIndex)
            this.getData()
        },
        handleShowGoogle2FA(googlr_2fa) {
            this.google2fa = googlr_2fa
            this.google2faDialogVisible = true
        },
        // 分页导航
        handlePageChange(val) {
            this.pageIndex = val
            this.getData()
        },
        //excel範本
        excelEx() {
            domainSourceExport().then((res) => {
                const xlsx = 'application/vnd.ms-excel'
                const blob = new Blob([res], { type: xlsx })
                const a = document.createElement('a') // 轉換完成，建立一個a標籤用於下載
                a.download = `${this.$t('範本下載')}.xlsx`
                a.href = window.URL.createObjectURL(blob)
                a.click()
                a.remove()
            })
        },
        //選取file
        changeFile(file, fileList, key) {
            this.form[key] = []
            for (var i in fileList) {
                this.form[key].push(fileList[i].raw)
            }
            this.$refs['form'].validateField(key)
        },
        //移除file
        handleRemove(file, fileList) {
            this.form['excel'] = []
            for (var i in fileList) {
                this.form['excel'].push(fileList[i].raw)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    padding-right: 20px;
}
.handle-box {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.red {
    color: #ff0000;
}
.selectWidth {
    width: 100%;
}
.excel-upload-input {
    display: none;
}
</style>
<style lang="scss">
@media screen and (max-width: 800px) {
    .el-dialog {
        width: 80% !important;
    }
}
</style>